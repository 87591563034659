import { Module, MutationAction, VuexModule } from 'vuex-module-decorators';
import store from '@/store';

type ModalName = 'refill' | 'replaceItem' | 'pushNotifications';

export type ModalData = any;

export type IModal = {
    name: ModalName;
    data?: ModalData;
};

@Module({namespaced: true, store, name: 'modal'})
export class ModalModule extends VuexModule {
    public name: ModalName | null = null;
    public data: ModalData | null = null;

    @MutationAction({mutate: ['name', 'data']})
    public async toggleModal({name, data = null}: IModal) {
        return {name, data};
    }
}
